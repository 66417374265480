<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import axios from "axios";

let user = JSON.parse(localStorage.getItem("user"));
let token = user.token;

export default {
  page: {
    title: "Kullanıcı Güncelleme",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Kullanıcı Güncelleme",
      items: [
        {
          text: "RTS",
          href: "/",
        },
        {
          text: "Kullanıcılar",
          href: "/users",
        },
        {
          text: "Kullanıcı Güncelleme",
          active: true,
        },
      ],
      isAlertVisibleSuccess: false,
      isAlertVisibleDanger: false,
      isAlertPassword: false,
      values: {
        name: "",
        email: "",
        passwordnew: "",
        passwordagain: "",
        authority: [],
      },
      statusOptions: [
        { value: 1, text: "Admin" },
        { value: 2, text: "Müdür" },
        { value: 3, text: "Muhasebe" },
        { value: 4, text: "Rezervasyon" },
        { value: 5, text: "Araç Şöförü" },
      ],
    };
  },
  mounted() {
    const itemId = this.$route.params.id;
    const api_url = process.env.VUE_APP_BASEURL + `/user/${itemId}`;

    axios
      .get(api_url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        this.values = response.data?.data;
      })
      .catch((error) => {
        console.error(error);
      });
  },
  methods: {
    showAlertSuccess() {
      this.isAlertVisibleSuccess = true
    },
    showAlertDanger() {
      this.isAlertVisibleDanger = true
    },
    update() {
      const itemId = this.$route.params.id;
      const api_url = process.env.VUE_APP_BASEURL + `/user/${itemId}`;

      const data = {
        name: this.values.name,
        email: this.values.email,
        authority: JSON.stringify(this.values.authority),
      }

      axios
        .put(api_url, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          this.showAlertSuccess();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    password() {
      const itemId = this.$route.params.id;
      const api_url = process.env.VUE_APP_BASEURL + `/user/${itemId}/password`;

      const data = {
        password: this.values.passwordnew,
        passwordagain: this.values.passwordagain,
      }

      if (this.values.passwordnew == this.values.passwordagain) {
        axios
          .put(api_url, data, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then(() => {
            this.showAlertSuccess();
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        this.isAlertPassword = true
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row justify-content-center">
      <div class="col-12 col-md-12">
        <div class>
          <b-alert v-if="isAlertVisibleSuccess" show variant="success">Başarıyla güncellendi.</b-alert>
          <b-alert v-if="isAlertVisibleDanger" show variant="danger">Hata! Lütfen daha sonra tekrar deneyiniz.</b-alert>
          <b-alert v-if="isAlertPassword" show variant="danger">Hata! Şifreler birbiriyle eşleşmiyor.</b-alert>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="card mt-4">
          <div class="card-body">
            <form @submit.prevent="update">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group mb-3">
                    <label for="model">İsim Soyisim</label>
                    <input v-model="values.name" type="text" class="form-control" />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group mb-3">
                    <label for="email">Mail Adresi</label>
                    <input v-model="values.email" type="text" class="form-control" />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group mb-3">
                    <label for="status">Yetki:</label>
                      <b-form-checkbox-group v-model="values.authority" class="form-control">
                        <b-form-checkbox v-for="(option, index) in statusOptions" :key="index" :value="option.value">
                          {{ option.text }}
                        </b-form-checkbox>
                      </b-form-checkbox-group>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-end align-items-center">
                <button type="submit" class="btn btn-primary px-4">Kaydet</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="card mt-4">
          <div class="card-body">
            <form @submit.prevent="password">

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group mb-3">
                    <label for="email">Şifre</label>
                    <input v-model="values.passwordnew" type="password" class="form-control" />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group mb-3">
                    <label for="status">Şifre Tekrar</label>
                    <input v-model="values.passwordagain" type="password" class="form-control" />
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-end align-items-center">
                <button type="submit" class="btn btn-primary px-4">Kaydet</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
